import React, { useState } from "react";
import Breadcrumbs from "../components/common/breadcrumbs/Breadcrumbs";
import AccountPageNavigation from "../components/account/AccountPageNavigation";
import Layout from "../layout/Layout";
import RepresentativeNavigation from "../components/account/RepresentativeNavigation";
import useGetRepresentativesDetails from "../hooks/representatives-details/useGetRepresentativesDetails";
import { staticPage } from "../../config/staticPage";
import Loader from "../components/form/Loader";
import { parseQueryString } from "../utils/url";

const Iframe = ({ url }) => {
  const [iframeLoading, setIframeLoading] = useState(true);

  const handleIframeLoad = () => {
    setIframeLoading(false);
  };

  return (
    <>
      {iframeLoading ? <Loader /> : null}
      <iframe
        src={`${process.env.GATSBY_BC_BASE_URL}${url}`}
        title="My-Account"
        id="my-account-page"
        height="0"
        onLoad={handleIframeLoad}
        style={{ display: iframeLoading ? "none" : "block" }}
        width="100%"
      />
    </>
  );
};

const Returns = ({ location }) => {
  const { order_id } = parseQueryString(location?.search);
  const [orderID] = useState(order_id || null);
  const { details: representative } = useGetRepresentativesDetails();

  if (orderID) {
    return (
      <div className="page-wrapper account-page-wrapper">
        <div className="container">
          <Breadcrumbs location={location} />
        </div>

        <div className="container">
          <div className="account-content">
            <div className="row flex flex-wrap page-layout">
              <div className="col page-sidebar">
                <div className="page-sidebar-inner">
                  <AccountPageNavigation path={"/returns"} />
                  {representative && (
                    <RepresentativeNavigation representative={representative} />
                  )}
                </div>
              </div>
              <div className="col page-content">
                <div className="page-content-inner">
                  <div className="page-heading-section">
                    <h1 className="page-title">returns</h1>
                  </div>

                  <Iframe url={`/account.php?action=new_return&order_id=${orderID}`} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div className="page-wrapper account-page-wrapper">
          <div className="container">
            <Breadcrumbs location={location} />
          </div>

          <div className="container">
            <div className="account-content">
              <div className="row flex flex-wrap page-layout">
                <div className="col page-sidebar">
                  <div className="page-sidebar-inner">
                    <AccountPageNavigation path={"/returns"} />
                    {representative && (
                      <RepresentativeNavigation
                        representative={representative}
                      />
                    )}{" "}
                  </div>
                </div>
                <div className="col page-content">
                  <div className="page-content-inner">
                    <div className="page-heading-section">
                      <h1 className="page-title">returns</h1>
                    </div>

                    <Iframe url={`/account.php?action=view_returns`} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export const Head = () => {
  let login_nav = staticPage;
  let loginDetails = null;

  for (const obj of login_nav) {
    if (obj.name === "returns") {
      loginDetails = obj;
      break; // Exit the loop once the desired object is found.
    }
  }
  return (
    <>
      <title>{loginDetails?.title}</title>
      <meta name="description" content={loginDetails?.description} />
    </>
  );
};

Returns.Layout = Layout;
export default Returns;
