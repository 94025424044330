import qs from "query-string";

const bigcommerceURL = "https://www.midwestgoods.com";
const storefrontURL = "https://express.midwestgoods.com";

export function stringifyURL(url, query) {
  return `${url}?${qs.stringify(query)}`;
}

export function stringifyParams(params) {
  return qs.stringify(params);
}

export const pushState = (base_path, params) => {
  // This check is necessary if using the hook with Gatsby...
  if (typeof window !== "undefined") {
    window.history.pushState({}, "", base_path + stringifyParams(params));
  }
};

export const redirectTo = (input) => {
  const regex = /redirect_to=([^"]+)/;
  const match = input.match(regex);

  if (match && match[1]) {
    return match[1];
  }

  return "/";
};

export const parseQueryString = (input) => {
  // Remove the leading "?" if present
  if (input.startsWith("?")) {
    input = input.substr(1);
  }

  // Split the string into key-value pairs
  const pairs = input.split("&");

  // Create an object to store the parsed values
  const result = {};

  // Iterate over each key-value pair
  pairs.forEach((pair) => {
    const [key, value] = pair.split("=");

    // Decode the URI component and assign the value to the corresponding key
    result[key] = decodeURIComponent(value);
  });

  return result;
};

export const replaceBigCommerceUrlToHeadlessUrl = (inputString) => {
  var regex = new RegExp(bigcommerceURL, "g");

  // Use the replace method to replace all occurrences of the old URL with the new one
  return inputString.replace(regex, storefrontURL);
};

